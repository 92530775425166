<template>
  <div>
    <h1 style="padding-top: 15px">The Legal, Institutional and Technical Architecture of ADMS in India</h1>
    <b-tabs content-class="mt-3">
      <Context1></Context1>
      <Context2></Context2>
      <Context3></Context3>
    </b-tabs>
    <div
      style="
        display: flex;
        padding-top: 20px;
        padding-bottom: 10px;
        flex-direction: column;
        align-items: center;
      "
    >
      <div class="">
        <a rel="license" href="http://creativecommons.org/licenses/by/4.0/"
          ><img
            alt="Creative Commons License"
            style="border-width: 0"
            src="https://i.creativecommons.org/l/by/4.0/88x31.png"
        /></a>
      </div>

      <div class="">
        This work is licensed under a
        <a rel="license" href="http://creativecommons.org/licenses/by/4.0/"
          >Creative Commons Attribution 4.0 International License</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Context1 from "@/components/Context-Tab-1.vue";
import Context2 from "@/components/Context-Tab-2.vue";
import Context3 from "@/components/Context-Tab-3.vue";

export default {
  name: "Home",

  components: {
    Context1: Context1,
    Context2: Context2,
    Context3: Context3
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
