<template>
  <div class="harms">
    <Context></Context>
  </div>
</template>

<script>
import Context from "@/components/Context-Content.vue";
export default {
  name: "Home",

  components: {
    Context: Context
  },
  mounted() {},
  methods: {}
};
</script>
